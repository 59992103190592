<template>
  <div class="content">
    <!--        顶部背景图-->
    <div class="top">
      <img :src="topBanner" alt="">
    </div>
    <div style="padding: 14px;">
      <!--        充值账号-->
      <div class="TopUpAccountWrap">
        <div v-if="type===1" class="TopUpAccountTitle">充值账号</div>
        <div class="TopUpAccountContent">
          <input v-model="TopUpAccount" class="TopUpAccount" placeholder="请输入手机号码" type="number" disabled>
        </div>
        <div v-if="type===1" class="TopUpAccountRules">
          <p class="TopUpAccountRulesText">1.该账号为所充值视频平台的账号，请仔细核对账号信息</p>
          <p class="TopUpAccountRulesText">2.请确保充值账号无误，充值成功后不支持退换</p>
          <p class="TopUpAccountRule sText">3.本次充值不提供发票</p>
        </div>
        <div v-if="type===2" class="TopUpAccountRules">
          <p class="TopUpAccountRulesText">1.充值仅限会员手机号，优惠充值24小时内到账</p>
          <p class="TopUpAccountRulesText">2.一经充值成功，无法退换</p>
          <p class="TopUpAccountRulesText">3.本次充值不提供发票</p>
        </div>
      </div>
      <!--        视频面额-->
      <div v-if="type === 1" class="TopUpDenominationWrap">
        <div v-for="(item,index) in videoList" :key="index" class="TopUpDenominationContent">
          <div
            class="TopUpDenominationM"
            :class="index%3 === 1 ? 'TopUpDenominationM_margin' : ''"
            @click="VideoPay(item)"
          >
            <div
              style="padding: 20px 0 0 0;"
              :class="price == videoList[index].buyPrice ? 'TopUpDenominationContentM_v' : 'TopUpDenominationContentM_vn'"
            >
              <div class="videoListName">{{ item.itemName }}</div>
              <div class="videoListmembers">会员价：<span
                style="font-size: 17px;color: #E33F44;font-weight: 700;"
              >{{ item.sale_price }}</span>元
              </div>
              <div class="videoListWebsite">官网价：{{ item.official_price }}元</div>
              <div
                :class="price === videoList[index].buyPrice ? 'vqSm' :'vqsn'"
                style="color:#ffffff;line-height:33px;border-radius: 0 0 7px 7px;"
              >
                <p v-if="price === videoList[index].buyPrice">火爆疯抢</p>
                <p v-else>强烈推荐</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--        手机面额-->
      <div v-if="type === 2" class="TopUpDenominationWrap">
        <p class="TopUpDenominationText">充值金额</p>
        <div class="TopUpDenominationContentM">
          <div
            v-for="(item,index) in mobileList"
            :key="index"
            class="TopUpDenominationM"
            :class="index%3 === 1 ? 'TopUpDenominationM_margin' : ''"
            @click="TopUpDenominationSel(item)"
          >
            <div
              style="padding: 20px 0;"
              :class="price === mobileList[index].price ? 'TopUpDenominationContentM_s' : 'TopUpDenominationContentM_n'"
            >
              <div>
                <span style="font-size: 20px;font-weight: 500;">{{ item.oriPrice }}</span>
                <span style="font-size: 12px;">元</span>
              </div>
              <p style="font-size: 12px;">售价：{{ item.price }}元</p>
            </div>
          </div>
        </div>
      </div>
      <!--        支付方式-->
      <div class="payTypeWrap">
        <div class="payType">支付方式</div>
        <div class="payTypeContent">
          <div class="payType1">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-11/13/yuelvhuiMcMoIOBrli1605074323.png"
              alt=""
              style="width: 18px;height: 18px;margin-right: 6px"
            >
            <p style="font-size:13px;font-weight: 500; ">微信支付</p>
          </div>
          <div class="payType2">
            <img
              style="width: 18px;height: 18px;"
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-11/14/yuelvhui91etV0Co4E1605074854.png"
              alt=""
            >
          </div>
        </div>
      </div>
      <!--            -->
      <div style="height: 77px;" />
    </div>
    <!--        立省-->
    <div class="province">
      立省{{ province }}元
    </div>
    <!--        底部立即支付-->
    <van-row class="total">
      <van-col :span="18" class="total_cen">
        <van-row type="flex" class="cen_le">
          <van-col :span="11" class="cen_t1">合计待支付</van-col>
          <van-col :span="7" class="cen_t2">¥{{ price | pr_status }}</van-col>
          <van-col :span="6">
            <van-dropdown-menu direction="up">
              <van-dropdown-item ref="item" title="明细">
                <div class="total_box">
                  <ul>
                    <li><span>总金额</span><span>¥{{ price | pr_status }}</span></li>
                  </ul>
                </div>
              </van-dropdown-item>
            </van-dropdown-menu>
          </van-col>
        </van-row>
      </van-col>
      <van-col :span="6" class="tot_ok" @click="TopUpPay">立即支付</van-col>
    </van-row>
  </div>
</template>

<script>
import Vue from 'vue'
import { Cell,
  CellGroup,
  Col,
  DropdownItem,
  DropdownMenu,
  Radio,
  RadioGroup,
  Row,
  Toast
} from 'vant'

Vue.use(Toast).use(Radio).use(RadioGroup).use(CellGroup).use(Cell)
  .use(Col).use(Row).use(DropdownMenu).use(DropdownItem)
import { formatNumber } from '@/utils/common/utils'
import { getVideoTopUpData, getVideoTopUpPay, getMobileTopUpData, getMobileTopUpPay } from '@/services/comeOn.js'
import { weChatPay } from '@/utils/payment'

export default {
  name: 'VideoTopUp',
  filters: {
    pr_status(v) {
      if (v === '') {
        return '0.00'
      } else {
        return formatNumber(v, 2, '.', ',')
      }
    }
  },
  data() {
    return {
      id: '',
      indexType: '',
      topBanner: '',
      TopUpAccount: '',
      isVideoType: true, // 视频充值选择值
      videoList: [], // 视频充值列表
      mobileList: [], // 手机充值列表
      radio: '1',
      price: '',
      oriPrice: '',
      province: 0
    }
  },
  created() {
    this.id = this.$route.query ? this.$route.query.id : ''
    this.indexType = Number(this.$route.query ? this.$route.query.topUptype : '')// 1视频2手机
    this.price = Number(this.$route.query ? this.$route.query.price : '')

    if (this.indexType === 1) {
      this.getVideoTopUpDetail()
    } else if (this.indexType === 2) {
      this.getMobileTopUpDetail()
    }
  },
  mounted() {

  },
  methods: {
    // 视频充值详情
    getVideoTopUpDetail() {
      getVideoTopUpData({
        projectId: this.id
      }).then(res => {
        if (Number(res.code) === 200) {
          this.topBanner = res.data.goods_info.bgImg// 头部背景图
          this.TopUpAccount = res.data.mobile// 充值账号
          this.videoList = res.data.list// 面额
        } else {
          Toast(res.msg)
        }
      })
    },
    // 视频充值选择
    VideoPay(item) {
      this.price = item.sale_price// 会员价
      this.oriPrice = item.official_price// 原价
      this.province = ((this.oriPrice) - (this.price)).toFixed(2)
    },
    // 手机充值详情
    getMobileTopUpDetail() {
      getMobileTopUpData().then(res => {
        if (Number(res.code) === 200) {
          this.topBanner = 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-10/19/yuelvhuiIEitBjzsl81605008834.png'
          this.TopUpAccount = res.data.phone
          this.mobileList = res.data.list
        } else {
          Toast(res.msg)
        }
      })
    },
    // 手机充值面额选择
    TopUpDenominationSel(item) {
      this.price = item.price// 优惠价格
      this.oriPrice = item.oriPrice// 原价
      this.province = ((this.oriPrice) - (this.price)).toFixed(2)
      this.p_id = item.p_id
    },
    // 充值接口
    TopUpPay() {
      if (this.price === '') {
        Toast('请选择金额')
        return false
      } else {
        if (this.type === 1) {
          this.VideoTopUpPay()
        } else if (this.type === 2) {
          this.MobileTopUpPay()
        }
      }
    },
    // 视频充值支付
    VideoTopUpPay() {
      const _this = this
      getVideoTopUpPay({
        p_type: 152,
        mobile: _this.TopUpAccount,
        p_id: _this.p_id,
        payVersion: 3,
        returnUrl: 'https://www.yuetao.group',
        openId: localStorage.getItem('openid'),
        dataType: '22A2607084CA26A21F15E3E01D83D261'
      }).then(res => {
        if (Number(res.code) === 200) {
          const params = res.pay.getwayBody
          _this.wxPay(params)
        } else {
          Toast(res.msg)
        }
      })
    },
    // 手机充值支付
    MobileTopUpPay() {
      const _this = this
      getMobileTopUpPay({
        payType: 152,
        phone: _this.TopUpAccount,
        paySource: 1,
        id: _this.id,
        openId: localStorage.getItem('openid'),
        payVersion: 3,
        returnUrl: 'https://www.yuetao.group',
        sourceKey: '22A2607084CA26A21F15E3E01D83D261'
      }).then(res => {
        if (Number(res.code) === 200) {
          const params = res.pay.getwayBody
          _this.wxPay(params)
        } else {
          Toast(res.msg)
        }
      })
    },
    /**
     * 调用微信支付
     * 2021-06-04 10:22:15
     * @author SnowRock
     * @param params
     */
    wxPay(params) {
      var that = this
      weChatPay(params).then(res => {
        // 支付成功后的操作
        Toast('支付成功')
        that.isDisabledSubmitBtn = true
      }).catch(e => {
        if (e) {
          const { msg = null } = e
          if (msg === 'isNotWeChat') {
            Toast('请在微信浏览器下进行支付')
          }
          if (msg === 'cancel') {
            // 取消支付的操作
            // 取消支付的操作
            Toast('取消支付')
          }
          if (msg === 'fail') {
            // 支付失败的操作
            Toast('支付失败')
          }
        }
        that.isDisabledSubmitBtn = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.content {
  /*position: fixed;*/
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-family: "PingFang SC Regular";
  text-align: left;
}

.top {
  width: 100%;
  height: 70px;
}

.top img {
  width: 100%;
  height: 100%;
}

.TopUpAccount {
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  background: none;
  padding: 20px 0 20px 0;
  font-size: 22px;
  color: #999;
  font-family: 'PingFang SC Regular';
  margin-bottom: 10px;
}

input::-webkit-input-placeholder {
  color: #999;
}

.TopUpAccountRules {
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 11px;
  margin-bottom: 27px;
}

.TopUpAccountRulesText {
  color: #666666;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;

}

/*    面额*/
.TopUpDenominationWrap {
  margin-bottom: 20px;
}

.TopUpDenominationContent, .TopUpDenominationContentM {
  display: flex;
  flex-wrap: wrap;
}

.TopUpDenominationContentM_s {
  color: #E33F44;
  background-color: #FCEEEE;
  border: 1px solid #E33F44;
  border-radius: 7px;
}

.TopUpDenominationContentM_n {
  border: 1px solid #CACACA;
  color: #999;
  border-radius: 7px;
}

.TopUpDenominationM, .TopUpDenominationV {
  width: 32%;
  text-align: center;
  margin-bottom: 10px;
}

.TopUpDenominationM_margin {
  margin-left: 2%;
  margin-right: 2%;
}

/*手机充值面额*/
.TopUpDenominationText {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #333;
}

/*    视频充值面额*/
.TopUpDenominationContentM_v {
  background-color: #FCEEEE;
  border: 1px solid #E33F44;
  border-radius: 7px;
}

.TopUpDenominationContentM_vn {
  background-color: #fff;
  border: 1px solid #E33F44;
  border-radius: 7px;
}

.vqSm {
  background-color: #E33F44;
}

.vqsn {
  background-color: #FA937B;
  border: 1px solid #FA937B;
}

.videoListName {
  font-weight: 700;
  font-size: 17px;
  color: #333;
  margin-bottom: 22px;
}

.videoListmembers {
  font-size: 11px;
  color: #E33F44;
  font-weight: 500;
}

.videoListWebsite {
  color: #E33F44;
  font-size: 11px;
  margin-bottom: 17px;
}

/*    支付方式*/
.payTypeWrap {
  font-size: 14px;
  color: #333;
  font-family: 'PingFang SC Medium';
  font-weight: 700;
  /*padding-bottom: 77px;*/
}

.payType {
  margin-bottom: 26px;
}

.payTypeContent {
  display: flex;
  border-bottom: 1px solid #E8E8E8;
  padding: 20px 0 20px 0;
}

.payType1 {
  display: flex;
}

.payType2 {
  margin-left: auto;
}

.province {
  position: fixed;
  bottom: 50px;
  right: 22px;
  padding: 9px 15px;
  height: 30px;
  text-align: center;
  /*line-height: 30px;*/
  color: #fff;
  background-color: #FA937B;
  border-radius: 15px 15px 15px 0;
  z-index: 99;
}

.total {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  .tot_ok {
    background-color: #F7263C;
    color: #fff;
    padding: 15px;
  }

  .total_cen {
    background-color: #FFFFFF;

    .cen_le {
      align-items: center;

      .cen_t1 {
        font-size: 15px;
        color: #333333;
        padding-left: 13px;
      }

      .cen_t2 {
        color: #F7263C;
        font-size: 17px;
        font-weight: 600;
        text-align: justify;
        text-justify: newspaper;
        word-break: break-all;
      }

      .total_box {
        padding: 20px;

        & > ul {
          & > li {
            display: flex;
            color: #333333;

            & > span:nth-child(1) {
              flex: 12;
              text-align: left;
              font-size: 15px;
            }

            & > span:nth-child(2) {
              flex: 12;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
</style>
